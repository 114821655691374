import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';

@Component({
	selector: 'sl-button',
	templateUrl: './button.component.html',
	styleUrl: './button.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ButtonModule, MatIconModule, SplitButtonModule]
})
export class ButtonComponent {
	readonly labelSignal$ = input<string>(null, { alias: 'label' });
	readonly severitySignal$ = input<string>('regular', { alias: 'severity' });
	readonly iconSignal$ = input<string>(null, { alias: 'icon' });
	readonly disabledSignal$ = input<boolean>(false, { alias: 'disabled' });
	readonly typeSignal$ = input<string>('button', { alias: 'type' });

	readonly onClick = output();
}
