<p-button
	styleClass="sl-button sl-button--{{ severitySignal$() }}"
	[type]="typeSignal$()"
	[disabled]="disabledSignal$()"
	(onClick)="onClick.emit($event)">
	@if (iconSignal$()) {
		<mat-icon class="sl-button-icon material-symbols-outlined">{{ iconSignal$() }}</mat-icon>
	}
	@if (labelSignal$()) {
		<span class="sl-button-label">{{ labelSignal$() }}</span>
	}
</p-button>
